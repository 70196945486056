import React from 'react';
import styled from 'styled-components';
import loadable from "@loadable/component"
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import '../../styles/globals.css';
import { Link, graphql } from 'gatsby';
import { MetaComponent } from '../../components';
import {
  Button,
  SassyText,
  Section,
  Text,
  Divider,
  SectionWrapper,
  SubTitle,
} from '../../components/core';
import { useMixpanel } from '../../context/MixpanelContext';

import Bullet from '../../assets/images/bullet.svg';
import HeroSection from '../../components/business-types/HeroSection';
import AboutBSUS from '../../components/business-types/AboutBSUS';
import { OnDesktopMod, OnMobileMod, Testimonial, testimonialBgStyles, TestimonialText, TestimonialTextWrapper, TestimonialCard, TestimonialImgContainer } from '../../components/Testimonials';
import { OnDesktop, OnMobile } from '../../components/DesktopMobileComps';
import { LeadFormProvider } from '../../context/LeadFormContext';

import TestimonialBgDesktop from "../../assets/images/testimonial_bg_desktop.svg";
import TestimonialBgMobile from "../../assets/images/testimonial_bg_mobile.svg";

const Footer = loadable(() => import("../../components"), {
  resolveComponent: (components) => components.Footer,
});
const LeadForm = loadable(() => import("../../components/LeadForm"));
const Navbar = loadable(() => import("../../components"), {
  resolveComponent: (components) => components.Navbar,
});

const Coverage = loadable(() => import("../../components/Coverage"));
const HelpSection = loadable(() => import("../../components/HelpSection"));
const VideoSection = loadable(() => import("../../components/VideoSection"));

const Shop = () => {
  const {t} = useTranslation(['bsus', 'common'])
  const mixpanel = useMixpanel()
  const [isQuoteModalOpen, setIsQuoteModalOpen] = React.useState(false);

  const handleQuoteModalOpen = () => {
    setIsQuoteModalOpen(true);
  };

  const handleQuoteModalClose = () => {
    setIsQuoteModalOpen(false);
  };

  const handleGetQuoteClick = (mxEventName = "") => {
    if (mxEventName) mixpanel.track(mxEventName)
    handleQuoteModalOpen()
  }

  return (
    <>
      <LeadFormProvider>
        {isQuoteModalOpen ? <LeadForm onModalClose={handleQuoteModalClose} /> : null}
      </LeadFormProvider>
      <MetaComponent
        title="Restaurant Insurance - Verak"
        description="Protect your business from pressing risks like fire, lightning, explosion and natural disasters. Get a quote."
        image="bsus"
        url="fire/bsus"
      />
      <Navbar active="products" />
      <HeroSection 
        pageName="restaurant"
        content={(
          <>
            All kinds of eateries- restaurants, cafes, food stalls and hotels- face some common risks such as:
            <ul style={{listStylePosition: "inside"}}>
              <li>Property and furniture damage due to fire or flood</li>
              <li>Risks of theft or burglary</li>
              <li>Intentional damage by third parties</li>
              <li>Destruction due to riots and strikes</li>
              <li>And more</li>
            </ul>
          </>
        )}
      />

      <Section background="rgba(255, 148, 80, 0.1)" style={{padding: "20px 0"}}>
        <SectionWrapper px="0">
          <Text fontSize="18px" lineHeight="30px" mfontSize="16px" mlineHeight="24px" style={{textAlign: "center"}}>
            That is why we offer Vyapar Suraksha (technically called Bharat Sookshma Udyam Suraksha)- the cheapest and best way to protect your restaurant from common risks.
          </Text>
        </SectionWrapper>
      </Section>

      <Section background="#fff">
        <SectionWrapper desktopStyles={{paddingBottom: "0"}}>
          <SubTitle fontSize="38px" lineHeight="68px" mfontSize="26px" mlineHeight="46px">
            Hear from Mr. Shubham G. who recently <SassyText>insured his restaurant with us!</SassyText>
          </SubTitle>
          <ModifiedTestimonialCard>
            <TestimonialImgContainer>
                <OnDesktopMod>
                    <StaticImage 
                        src="../../assets/images/testimonial_shubham_g.webp"
                        alt="testimonial_shubham_g"
                        loading="lazy"
                        style={{width: 'min(370px, 100%)'}}
                    />
                </OnDesktopMod>
                <OnMobileMod>
                    <StaticImage 
                        src="../../assets/images/testimonial_shubham_g_mobile.webp"
                        alt="testimonial_shubham_g"
                        loading="lazy"
                        style={{width: 'min(160px, 100%)',borderRadius: '50%', maxHeight: "160px"}}
                    />
                </OnMobileMod>
            </TestimonialImgContainer>
            <Testimonial>
                <TestimonialTextWrapper>
                <OnDesktop>
                    <TestimonialBgDesktop
                        style={testimonialBgStyles}     
                    />
                </OnDesktop>
                <OnMobile>
                    <TestimonialBgMobile
                    style={{...testimonialBgStyles, height: '100%'}}                
                    />
                </OnMobile>
                <TestimonialText color="#4D4D4D" mfontSize="14px" marginTop="4rem" mMarginTop="1rem">
                    I have always struggled with insurance agents, but with Verak, <strong>it was the best experience.</strong> There was nothing I did not like. <strong>Payment was easy, experience was smooth.</strong> Sabse achi baat yeh thi ki mujhe kahi nahi jana pada insurance karne ke liye. I got everything done on WhatsApp and <strong>received the policy within 2 days</strong> of payment!
                </TestimonialText>
                </TestimonialTextWrapper>
                <br />
                <Text color="#000000" fontWeight="bold" fontSize="20px" mfontSize="14px">Shubham G.</Text>
                <Text color="#4D4D4D" fontSize="14px" style={{marginTop: '0'}} mfontSize="14px">Restaurant owner in Ranchi</Text>
            </Testimonial>
            </ModifiedTestimonialCard>
        </SectionWrapper>
      </Section>

      <AboutBSUS />
      <VideoSection videoLink="https://www.youtube.com/embed/bVXE3x9zc7A" style={{paddingTop: "80px"}} />
      <Coverage type="BSUS" enableTranslations={false} />

      <Section background="#ffffff">
        <SectionWrapper>
          <SubTitle>
            <Trans i18nKey="WhyBSUS.Title">
              Why opt for a <SassyText> BSUS </SassyText> policy?
            </Trans>
          </SubTitle>
          <Left>
            <Container>
              <ul>
                <li>
                  <Text fontWeight="bold" fontSize="16px" mfontSize="16px">
                    <Bullet className="bullet" />
                    {t("WhyBSUS.Protection.Title")}
                  </Text>
                  <Text
                    color="#999999"
                    fontSize="16px"
                    style={{ margin: '18px 0px 0px 30px' }}
                    mfontSize="16px"
                  >
                    {t("WhyBSUS.Protection.Desc")}
                  </Text>
                </li>
                <li>
                  <Text fontWeight="bold" fontSize="16px" mfontSize="16px">
                    <Bullet className="bullet" />
                    {t("WhyBSUS.RiskCoverage.Title")}
                  </Text>
                  <Text
                    color="#999999"
                    fontSize="16px"
                    style={{ margin: '18px 0px 0px 30px' }}
                    mfontSize="16px"
                   
                  >
                    {t("WhyBSUS.RiskCoverage.Desc")}
                  </Text>
                </li>
                <li>
                  <Text fontWeight="bold" fontSize="16px" mfontSize="16px">
                    <Bullet className="bullet" />
                    {t("WhyBSUS.AdditionalCovers.Title")}
                  </Text>
                  <Text
                    color="#999999"
                    fontSize="16px"
                    style={{ margin: '18px 0px 0px 30px' }}
                    mfontSize="16px"
                  >
                    {t("WhyBSUS.AdditionalCovers.Desc")}
                  </Text>
                </li>
              </ul>
            </Container>
            <Text fontSize="16px" mfontSize="16px">
              <Trans i18nKey="WhyBSUS.LearnAboutBSUS">
                Learn about
                <strong> Bharat Sookshma Udyam Suraksha (BSUS) </strong>
                in detail 
                <a href="https://blog.verak.in/product-explainers-bharat-sookshma-udyam-suraksha-bsus-policy-amp/">
                  <SassyText>HERE</SassyText>
                </a>
              </Trans>
            </Text>
            <Text
              fontSize="16px"
              style={{ margin: '40px 0px' }}
              mfontSize="16px"
             
            >
              <Trans i18nKey="WhyBSUS.OtherOptionsText">
                If your business’s total insurable assets at 
                <strong> any one location are worth more than ₹5 Crore </strong> ,
                you may want to consider opting for a 
              </Trans>
              <Link to="/fire/sfsp">
                <SassyText type="secondary">
                  {t('SFSPFullForm')} (SFSP)
                </SassyText>
              </Link>
              , {t('WhyBSUS.Or')} {t('WhyBSUS.A')}{' '}
              <Link to="/fire/blus">
                <SassyText type="secondary">
                {t('BLUSFullForm')} (BLUS)
                </SassyText>
              </Link> {''}
              <Trans i18nKey="WhyBSUS.InsurancePolicy">
                Insurance policy
              </Trans>!
            </Text>
            <Button wide label={t('CTALabels.GetAQuote')} onClick={() => handleGetQuoteClick("Clicked-Down - 'Get a Quote' - BSUS LP")} />
          </Left>
        </SectionWrapper>
      </Section>

      <HelpSection />
      <Divider />
      <Footer />
    </>
  );
};

export const query = graphql`
  query {
    locales: allLocale(
      filter: {
        ns: {
          in: ["bsus", "index", "common", "navbar", "footer", "getQuoteModal", "helpSection", "coverageSection", "videoSection"]
        }, 
        language: {eq: "en"}
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const ModifiedTestimonialCard = styled(TestimonialCard)`
    margin-top: 4rem;
    @media (max-width: 768px) {
        margin-top: 1.5rem;
    }
`

const Left = styled.div`
  text-align: left;

  @media screen and (max-width: 768px) {
    button {
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

const Container = styled.div`
  display: flex;
  align-items: start;
  margin: 20px 0px;

  ul {
    margin-top: 40px;
  }

  .bullet {
    margin-left: -20px;
    margin-right: 35px;
  }

  li {
    color: #999999;
    margin-left: 24px;
    margin-bottom: 24px;
    list-style: none;
  }
`;

export default Shop;
